@font-face {
    font-family: 'menuchic';
    src: url('./font/menuchic.eot?21837507');
    src: url('./font/menuchic.eot?21837507#iefix') format('embedded-opentype'),
        url('./font/menuchic.woff?21837507') format('woff'),
        url('./font/menuchic.ttf?21837507') format('truetype'),
        url('./font/menuchic.svg?21837507#menuchic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mc23';
    src: url('https://assets.foound.com/fonts/mc23.woff?21837507') format('woff'),
        url('https://assets.foound.com/fonts/mc23.ttf?21837507')
            format('truetype'),
        url('https://assets.foound.com/fonts/mc23.svg?21837507#menuchic')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

.mc23 {
    font-family: 'Mc23';
    font-style: normal;
    font-weight: normal;
    speak: never;
}

.menuchic-icon {
    font-family: 'menuchic';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    /*
  width: 1em;
  margin-right: .2em;
  */
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    /*line-height: 1em;*/

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /*margin-left: .2em;*/

    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

    /*width: 70px;*/
}

html,
body {
    height: 100%;
    /*scroll-behavior: auto !important;*/
}
body {
    display: flex;
    flex-direction: column;
    font-size: 1rem !important;
}

.MuiSvgIcon-root {
    touch-action: manipulation;
}

.blink {
    color: red !important;
    border: 3px solid red !important;
}

.MuiTypography-button,
.MuiButton-root {
    font-size: 1rem !important;
}

/*
#header {
  position: -webkit-sticky;
  position: sticky;
}
*/

button:focus {
    outline: 0 !important;
}
button {
    text-transform: none !important;
}

button.Mui-disabled {
    color: #000 !important;
}

#__next {
    /*height: 100%;*/
}

#product.order-unavailable .MuiIconButton-label,
#product.order-unavailable .MuiSvgIcon-root,
body.order-unavailable .MuiDialogActions-root {
    /*display: none !important;*/
}

.react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    position: relative;
    display: inline-block;
    margin: 0 auto;
    z-index: 9998;
    cursor: pointer;
}

.react-add-to-calendar__button {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #aab9d4;
    border-radius: 3px;
    color: #000;
}

.react-add-to-calendar__dropdown {
    position: absolute;
    top: 30px;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
}

.react-add-to-calendar__dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.react-add-to-calendar__dropdown ul li a {
    color: #000;
    text-decoration: none;
}

.nav-item {
    font-weight: bold;
    font-size: 1em;
}

#nav-dropdown {
    text-transform: capitalize;
}

.carousel-inner img {
    max-height: 80vh;
}

#productRight {
    display: none;
}

#informationMobile {
    display: block;
}

#tabContent {
    padding: 0px;
}

#tabs {
    /*width: auto;*/
    background-color: #ffffff;
    position: fixed;
    /*width: 100%;*/
    z-index: 1200;
}

#tabsInner {
    /*width: 1200px !important; */
    background-color: #ffffff;
}

/* froala   */

.fdb-block {
    background-color: transparent !important;
}

#floatingInfo {
    display: block;
    position: sticky;
    position: -webkit-sticky;

    z-index: 1201;
    width: 300px;
    max-width: 300px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

#floatingCart {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1201;
    width: 300px;
    max-width: 300px;
}

#product #floatingCart {
    z-index: 1200;
}

#menu #floatingCart {
    top: 106px;
}

#menu #floatingInfo {
    top: 106px;
}

#floatingCart #floatingCartContent {
    overflow-y: auto;
}

#floatingCartContentInner {
    max-height: calc(100vh - 138px);
    overflow: scroll;
}

#productRight #floatingCartContentInner {
    max-height: calc(100vh - 120px);
}

#productRight #floatingCart {
    position: sticky;
    position: -webkit-sticky;
    top: 68px;
    left: 0px;
    box-sizing: border-box;
    padding-left: 20px;
    width: 330px;
    max-width: 330px;
}

#floatingCartContent {
    margin-right: 0px;

    background: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    max-height: calc(100vh - 98px);
}

#navBar {
    background-color: #ffffff;
    z-index: 1198;
    /*
    position: sticky;
    position: -webkit-sticky;
    top: 48px;
    left: 0px;
    */
}

#layout {
    background-color: #ffffff;
}

#home #layout {
    background-color: transparent;
}

#media-scroll-text::-webkit-scrollbar {
    display: none;
}

#media-scroll-text {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}

@keyframes animateFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.img-loaded-fade {
    animation-duration: 1s;
    animation-name: animateFade;
    animation-iteration-count: 1;
}

@keyframes animateZoom {
    from {
        transform: translateZ(3px) scale(0.5);
    }
    to {
        transform: translateZ(3px) scale(1);
    }
}

.img-loaded-zoom {
    animation-duration: 1s;
    animation-name: animateZoom;
    animation-iteration-count: 1;
}

#map > div {
    touch-action: auto !important;
}

.MuiBackdrop-root {
    /* z-index: 1210 !important;*/
}

/*  MENU PAGE  */
body#menu #layout {
    background-color: #f9fafa;
}

body#menu #menulist .MuiPaper-root {
    background: transparent;
}
#floating-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

/*body#menu #menulist .menu-products-list {*/
.menu-products-list {
    display: grid;
    grid-gap: 30px 10px;
    padding-top: 10px;
}

/*body#menu #menulist .menu-products-grid {*/
.menu-products-grid {
    display: grid;
    grid-gap: 10px 10px;
    padding-top: 10px;
}

body#menu #menulist .MuiListItem-root {
    border-radius: 0px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    transition: 0.5s all;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
    background: #fff;
}
body#menu #menulist .MuiListItem-root:hover {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}

body#menu #menulist {
    padding: 20px;
}

body#menu #menulist .menu-products-grid .menu-product-item {
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 17%);
}

body#menu #menulist .menu-products-grid .menu-product-item:hover {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
.menu-product-item .title-outer {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.8) 59%
    );
    padding: 7px;
    padding-top: 100px;
}
.menu-product-item .title-inner {
    color: rgb(0, 0, 0);
}

body#booking #layout,
body#product #layout,
body#contact #layout,
body#account #layout,
body#cart #layout,
body#order_takeaway #layout,
body#checkout #layout,
body#login #layout {
    max-width: 964px !important;
}

body#language #layout,
body#currency #layout,
body#regime #layout {
    max-width: 580px !important;
}

body#product #productAddToCart {
    max-width: 964px !important;
    padding-left: 20px;
    padding-right: 20px;
    align-self: center;
    position: fixed;
    width: 100%;
    z-index: 16;
    left: auto;
    right: auto;
}

#productAddToCart {
    position: fixed;
    left: auto;
    right: auto;
    z-index: 13;
    margin: 0 auto;
}

#productAddToCartInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

#opening_hour_status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#opening_hour_status_inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 60px;
    justify-content: center;
}

#opening_hour_status_closed {
    flex: 1;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

#opening_hour_status_closed_inner {
    background: #e74856;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
}

#opening_hour_status_open {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#opening_hour_status_list {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#opening_hour_status_list_inner {
    margin-bottom: 7px;
    min-width: 200px;
    width: 100%;
}

.container-homebloc-inner {
    position: relative;
    margin: 0 auto;
}

#homebloc-address {
    border: solid 1px rgba(144, 144, 144, 0.25);
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px;
}

#homebloc-address .bloc-service-title {
    margin-bottom: 20px;
}

.homebloc-address-row {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    border-bottom: 1px solid rgba(144, 144, 144, 0.45);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.homebloc-address-row:last-of-type {
    border-bottom: none !important;
}

.homebloc-address-row-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 50px;
}

.homebloc-address-row-right {
    flex: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.homebloc-address-row-inner button {
    width: 100%;
    margin-left: 20px;
    color: #fff !important;
}

.container-homebloc[data-type='address'] {
    padding: 20px;
}
.container-homebloc[data-type='booking'] {
    padding: 20px;
}
.container-homebloc[data-type='cascadgrid'] {
    padding-top: 20px;
    padding-bottom: 20px;
}

/*  bouton cart sur mobile  */
#cart-m {
    z-index: 1199;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.5);
}

#cart-m-inner {
    padding: 0px;
    height: 45px;
}

#cart-m-btn {
    color: #ffffff;
    padding: 1px 16px;
}

#cart-m-btn-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Taille fixe Footer */
#footeree > div {
    max-width: 964px !important;
    margin: auto !important;
}

#home .bloc-timetable-outer {
    padding: 0px;
}

#home .bloc-timetable-title {
    /*margin-top:1em;*/
}
#home .jss54 {
    margin-right: 5px;
}
#home .jss56 {
    overflow-y: scroll;
}

#homebloc-address {
    background-color: rgba(255, 255, 255, 0.2) !important;
}



#special-closure-block {
    background-color: #16191d;
    z-index: 1202;
    position: relative;
}

body#menu #menulist .menu-products-list-product,
body#menu #menulist .menu-products-list-category,
body#menu #menulist .menu-products-list-1,
body#menu #menulist .menu-products-list-2,
body#menu #menulist .menu-products-list-3 {
    display: flex;
    flex-direction: column;
}

.checkIcon:focus,
.checkIcon:hover {
    filter: brightness(0) invert(0);
}

.qtty-component .MuiSvgIcon-root {
    filter: brightness(0) invert(100) !important;
}

.MuiSvgIcon-root .product-quantity:hover,
.MuiSvgIcon-root .product-quantity:focus {
    filter: brightness(0) invert(0) !important;
}

.menu-products-grid-product .menu-product-item,
.menu-products-grid-category .menu-product-item,
.menu-products-grid-0 .menu-product-item,
.menu-products-grid-1 .menu-product-item,
.menu-products-grid-2 .menu-product-item {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #fff;
    margin: 7px;
}

/* MENUS */

.menu-0 {
    flex: 1;
}

.menu-inner-0 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*  HEADER  */

header .nav-item a {
    text-transform: capitalize;
}

#headerImage {
    position: relative;
    display: block;
}

/*
.header_logo,
.header_logo_title {
    transition-property: height;
    transition-duration: 250ms;
    transition-delay: 250ms;
}
*/

.header-product {
    transition: all 250ms ease-out;
}

.outer-line-outer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.outer-line {
    flex: 1;
    border-bottom: 1px solid #d9d9d9;
}

.outer-line-label {
    display: inline-block;
    vertical-align: middle;
    background-color: #999999;
    color: #ffffff;
    border-radius: 7px;
    padding: 3px 10px 3px 10px;
    font-size: 13px;
    margin-bottom: -10px;
    margin-left: 10px;
    margin-right: 10px;
}

h6.jss8 {
    top: auto !important;
    height: auto !important;
    position: auto !important;
}

.jss12 {
    opacity: 1 !important;
}

/*
https://trello.com/c/MyHfYB6R/1120-bloquer-le-scroll-vers-le-haut-pour-%C3%A9viter-les-rechargements-intempestifs-de-page
*/

body {
    overscroll-behavior: contain;
}

#footer h6 {
    margin: 10px;
}

/* bug safari   */
.MuiPhoneNumber-flagButton svg {
    height: 30px;
    width: 30px;
}

/*Fix header height on first homebloc */
.splitHtml,
.imageImageContainer img {
    transition: all 1s ease-out;
}

#reminder-email legend {
    display: none;
}

.hide-legend fieldset legend {
    display: none;
}

#nbPeople {
    padding: 12px 14px;
}
.infosPageHeader {
    padding: 20px;
    background: rgba(220, 220, 220, 0.6);
    border-radius: 10px;
}

/* CSS Override on QuantitySelect component */
.MuiNativeSelect-root:before {
    border: none !important;
}
.MuiNativeSelect-root:after {
    border: none !important;
}
.MuiNativeSelect-root select {
    padding-right: 0 !important;
}

/* Fix calendar */
.react-datepicker-popper {
    z-index: 1200 !important;
}

/*
https://trello.com/c/fHSeVR0F/2755-les-menus-sur-le-site-demo-de-netto-pizzeria-ne-saffichent-pas
*/
body#menu {
    background-image: none !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    background-color:rgba(0, 0, 0, 0.4) !important;
    padding:10px !important;
    height:40px !important;
    width: 40px !important;
    background: 40%/70% 100% no-repeat;

}

.booking-date-picker {
    width: 100%;
}