@keyframes bounce {
    0%,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        transform: translate3d(0, -4px, 0);
    }
}
.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
}
@keyframes flash {
    0%,
    50%,
    to {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}
.flash {
    animation-name: flash;
    animation-duration: 1s;
}
@keyframes pulse {
    0%,
    to {
        transform: scaleX(1);
    }
    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }
}
.pulse {
    animation-name: pulse;
    animation-duration: 1s;
}
@keyframes rubberBand {
    0%,
    to {
        transform: scaleX(1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
}
.rubberBand {
    animation-name: rubberBand;
    animation-duration: 1s;
}
@keyframes shake {
    0%,
    to {
        transform: translateZ(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}
.shake {
    animation-name: shake;
    animation-duration: 1s;
}
@keyframes headShake {
    0% {
        transform: translateX(0);
    }
    6.5% {
        transform: translateX(-6px) rotateY(-9deg);
    }
    18.5% {
        transform: translateX(5px) rotateY(7deg);
    }
    31.5% {
        transform: translateX(-3px) rotateY(-5deg);
    }
    43.5% {
        transform: translateX(2px) rotateY(3deg);
    }
    50% {
        transform: translateX(0);
    }
}
.headShake {
    animation-timing-function: ease-in-out;
    animation-name: headShake;
    animation-duration: 1s;
}
@keyframes swing {
    20% {
        transform: rotate(15deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(0deg);
    }
}
.swing {
    transform-origin: top center;
    animation-name: swing;
    animation-duration: 1s;
}
@keyframes tada {
    0%,
    to {
        transform: scaleX(1);
    }
    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
}
.tada {
    animation-name: tada;
    animation-duration: 1s;
}
@keyframes wobble {
    0%,
    to {
        transform: none;
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate(-5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate(3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate(-3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate(2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate(-1deg);
    }
}
.wobble {
    animation-name: wobble;
    animation-duration: 1s;
}
@keyframes jello {
    0%,
    11.1%,
    to {
        transform: none;
    }
    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        transform: skewX(0.39063deg) skewY(0.39063deg);
    }
    88.8% {
        transform: skewX(-0.19531deg) skewY(-0.19531deg);
    }
}
.jello {
    animation-name: jello;
    transform-origin: center;
    animation-duration: 1s;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
.zoomIn {
    animation-name: zoomIn;
    animation-duration: 1s;
}
@keyframes zoomInDown {
    0% {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInDown {
    animation-name: zoomInDown;
    animation-duration: 1s;
}
@keyframes zoomInLeft {
    0% {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInLeft {
    animation-name: zoomInLeft;
    animation-duration: 1s;
}
@keyframes zoomInRight {
    0% {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInRight {
    animation-name: zoomInRight;
    animation-duration: 1s;
}
@keyframes zoomInUp {
    0% {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInUp {
    animation-name: zoomInUp;
    animation-duration: 1s;
}
@keyframes slideInDown {
    0% {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}
.slideInDown {
    animation-name: slideInDown;
    animation-duration: 0.6s;
}
@keyframes slideInLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}
.slideInLeft {
    animation-name: slideInLeft;
    animation-duration: 0.6s;
}
@keyframes slideInRight {
    0% {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}
.slideInRight {
    animation-name: slideInRight;
    animation-duration: 0.6s;
}
@keyframes slideInUp {
    0% {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}
.slideInUp {
    animation-name: slideInUp;
    animation-duration: 0.6s;
}

@keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: scaleX(1);
    }
}
.bounceIn {
    animation-name: bounceIn;
    animation-duration: 1s;
}
@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: none;
    }
}
.bounceInDown {
    animation-name: bounceInDown;
    animation-duration: 1s;
}
@keyframes bounceInLeft {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        transform: translate3d(5px, 0, 0);
    }
    to {
        transform: none;
    }
}
.bounceInLeft {
    animation-name: bounceInLeft;
    animation-duration: 1s;
}
@keyframes bounceInRight {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}
.bounceInRight {
    animation-name: bounceInRight;
    animation-duration: 1s;
}
@keyframes bounceInUp {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    75% {
        transform: translate3d(0, 10px, 0);
    }
    90% {
        transform: translate3d(0, -5px, 0);
    }
    to {
        transform: translateZ(0);
    }
}
.bounceInUp {
    animation-name: bounceInUp;
    animation-duration: 1s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.6s;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 0.6s;
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 0.6s;
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 0.6s;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.6s;
}
@keyframes lightSpeedIn {
    0% {
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        transform: skewX(-5deg);
        opacity: 1;
    }
    to {
        transform: none;
        opacity: 1;
    }
}
.lightSpeedIn {
    animation-name: lightSpeedIn;
    animation-timing-function: ease-out;
    animation-duration: 1s;
}
@keyframes rotateIn {
    0% {
        transform-origin: center;
        transform: rotate(-200deg);
        opacity: 0;
    }
    to {
        transform-origin: center;
        transform: none;
        opacity: 1;
    }
}
.rotateIn {
    animation-name: rotateIn;
    animation-duration: 1s;
}
@keyframes rotateInDownLeft {
    0% {
        transform-origin: left bottom;
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        transform-origin: left bottom;
        transform: none;
        opacity: 1;
    }
}
.rotateInDownLeft {
    animation-name: rotateInDownLeft;
    animation-duration: 1s;
}
@keyframes rotateInDownRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        transform-origin: right bottom;
        transform: none;
        opacity: 1;
    }
}
.rotateInDownRight {
    animation-name: rotateInDownRight;
    animation-duration: 1s;
}
@keyframes rotateInUpLeft {
    0% {
        transform-origin: left bottom;
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        transform-origin: left bottom;
        transform: none;
        opacity: 1;
    }
}
.rotateInUpLeft {
    animation-name: rotateInUpLeft;
    animation-duration: 1s;
}
@keyframes rotateInUpRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0;
    }
    to {
        transform-origin: right bottom;
        transform: none;
        opacity: 1;
    }
}
.rotateInUpRight {
    animation-name: rotateInUpRight;
    animation-duration: 1s;
}
