/*  MEDIA QUERY */

@media only screen and (min-width: 100px) {

    .splitContainer {
        flex-direction: column;
    }

    #floatingCart button.MuiButton-fullWidth {
        width: 100% !important;
    }

    .fadeIn button.MuiButton-fullWidth {
        /* width:80% !important;*/
        width: calc(100% - 120px) !important;
    }

    .fadeInDown button.MuiButton-fullWidth {
        margin-bottom: 10px !important;
    }

    /* END */

    #tabs {
        left: 1;
        right: 1;
    }

    #tabsInner {
        width: 100%;
    }

    #floating-right {
        display: none !important;
    }

    /*
    //https://trello.com/c/QE2A0Q3K/5048-affichage-plat-avec-longue-description

    body#menu #menulist .menu-products-list-product,
    body#menu #menulist .menu-products-list-category {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    body#menu #menulist .menu-products-grid-product,
    body#menu #menulist .menu-products-grid-category {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    */

    .menu-product-cc {
        flex-wrap: nowrap;
    }

    .menu-products-grid {
        padding: 0 10px;
    }

    .bloc-service-outer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bloc-service {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        align-content: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
    }

    .bloc-service-title,
    .bloc-timetable-title,
    grid-title {
        font-weight: bold;
        font-size: 24px;
        padding: 20px 0;
        text-align: center;
    }

    .bloc-service .bloc-service-inner {
        width: 100px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: 0px solid red;
    }

    .bloc-service .bloc-service-circle {
        background-color: #ffffff;
        border-radius: 40px;
        /*padding: 15px;*/
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }

    .bloc-service .bloc-service-circle i {
        font-size: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .bloc-service .bloc-service-inner img {
        width: 45px;
        height: 45px;
        padding: 10px;
    }

    .bloc-service .bloc-service-inner span {
        color: #ffffff;
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
    }

    .bloc-timetable-outer {
        border: 0px solid red;
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
    }

    .homebloc-manager-title {
        font-size: 21px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
    }

    .homebloc-manager-content {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
    }

    #homebloc-address-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #homebloc-address-left {
        width: 100%;
    }

    #homebloc-address-right {
        width: 100%;
    }

    .homebloc-booking-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 5px;
      
    }

    .homebloc-booking-timetable {
        width: 100%;
        border-bottom: 1px solid #b0b5b4;
        padding: 10px;
    }

    .homebloc-booking-booking {
        width: 100%;
        /*max-height: 370px;*/
    }

    .special-closure-block-title {
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        align-items: center;
        /*height: 50px;*/
        justify-content: center;
        padding-left: 7px;
        padding-right: 7px;
    }

    #button-cart {
        z-index: 1199;
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 20px;
        background-color: #ffffff;
        box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.5);
        margin: 0px !important;
    }

    #button-cart-inner {
        padding: 0px;
        height: 45px;
    }
}

@media only screen and (max-width: 768px) {

    .splitImage, .splitHtml {
        width: 100% !important;
    }
    #homebloc-address {
        border: none;
        background-color: none;
    }

    #homebloc-address-right {
        margin-top: 20px;
    }

    #mc_mainDrawer .homebloc-address-row {
        margin: none;
    }

    .MuiDrawer-paper .MuiListItem-root:hover {
        background-color: transparent !important;
    }
    /* CSS contrainte affichage boutons desktpo avec limitation */
    .MuiButton-fullWidth {
        width: 300px !important;
        margin: auto !important;
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) {


    .splitContainer {
        flex-direction: row;
    }

    .splitContainer_reverse {
        flex-direction: row-reverse;
    }
    


    /* CSS contrainte affichage boutons desktpo avec limitation */
    .MuiButton-fullWidth {
        max-width: 250px !important;
        margin: auto !important;
        display: flex !important;
    }

    #tabs {
        left: 'auto';
        right: 'auto';
    }

    #tabsInner {
       /* width: calc(100% - 320px);*/
       width:100%;
    }

    #floating-right {
        display: flex !important;
    }

    body#menu #menulist .menu-products-list-category,
    body#menu #menulist .menu-products-list-product {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    body#menu #menulist .menu-products-grid-category,
    body#menu #menulist .menu-products-grid-product {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .menu-product-cc {
        flex-wrap: nowrap;
    }
    /*
    body {
        background-color: rgb(0, 0, 0, 0.1) !important;
        background-size: cover;
        background-repeat: no-repeat;
    }
    */

    .MuiTabs-scrollButtons {
        display: none !important;
    }

    #informationMobile {
        display: none;
    }

    #productRight {
        display: block;
    }

    .padding20 {
        /*padding: 0 20px;*/
    }

    #tabContent {
        padding: 0px;
    }

    #homepage #floatingInfo #floatingInfoContent {
        overflow-y: auto;
    }

    #floatingInfoContent {
        /*margin-right: 20px;*/
        overflow-y: scroll;
        background: #ffffff;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        /*max-height: calc(100vh - 98px);*/
        max-height: 72vh;
    }

    #floatingInfoContentInner {
        max-height: calc(100vh - 138px);
        overflow: scroll;
    }

    #tabHeader {
        position: sticky;
        position: -webkit-sticky;
        top: 48px;
        left: 0px;
        background-color: #ffffff;
        z-index: 1198;
    }

    .bloc-service-title,
    .bloc-timetable-title,
    grid-title {
        font-size: 32px;
    }

    .bloc-service .bloc-service-inner {
        width: 150px;
        min-height: 130px;
    }

    .bloc-service .bloc-service-circle {
        border-radius: 60px;
    }

    .bloc-service .bloc-service-circle i {
        font-size: 40px;
        width: 70px;
        height: 70px;
        line-height: 70px;
    }

    .bloc-service .bloc-service-inner img {
        width: 68px;
        height: 68px;
    }

    .bloc-service .bloc-service-inner span {
        font-size: 16px;
        line-height: 19px;
    }

    .bloc-timetable-title {
        text-align: center;
        font-size: 32px;
    }

    .bloc-timetable-outer {
        max-width: 600px;
    }

    .homebloc-manager-title {
        font-size: 32px;
    }

    .homebloc-manager-content {
        font-size: 16px;
    }

    #homebloc-address-content {
        flex-direction: row;
        align-items: flex-start;
    }

    #homebloc-address-left {
        /*
        max-width: 75%; 
        flex: 1;
        */
        flex: auto;
    }

    #homebloc-address-right {
        /*max-width: calc(25% - 20px);*/
        max-width: 380px;
        min-width: 300px;
        padding-left: 10px;
        padding-right: 15px;
    }

    #container-homebloc-inner-address {
        padding: 20px;
    }

    .homebloc-booking-inner {
        flex-direction: row;
        align-items: end;
    }

    .homebloc-booking-timetable {
        width: 50%;
        border-bottom: none;
        margin: auto;
    }

    .homebloc-booking-booking {
        width: 50%;
       
    }

    .special-closure-block-title {
        font-size: 16px;
        line-height: 1em;
    }

    #opening_hour_status_list {
        padding: 20px !important;
    }
}

@media only screen and (min-width: 992px) {
    #floating-right {
        display: flex !important;
    }

    body#menu #menulist .menu-products-list-product {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    body#menu #menulist .menu-products-grid-product {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 1200px) {
    #floating-right {
        display: flex !important;
    }
}

@media only screen and (min-width: 1920px) {
    body#menu #menulist .menu-products-list-product {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    body#menu #menulist .menu-products-grid-product {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
